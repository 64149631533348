<template>
  <div
    class="indicadores full-width row wrap justify-center items-center content-center q-pa-none q-ma-none q-px-sm"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card
      class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none"
      style="min-height: calc(100vh - 60px)"
    >
      <div
        class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
        style=""
      >
        <interstellar-text id="indicadores" class="text-h3 q-ma-none q-pa-none">
          Indicadores
        </interstellar-text>
      </div>
      <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
        <iframe
          src="https://datastudio.google.com/embed/reporting/e55b5224-c0ba-4bda-8df8-5ab37ea6eba1/page/qN0IC"
          frameborder="0"
          style="border: 0; width: 100%; height: 100vh"
          allowfullscreen
          title="Data Studio Indicadores"
          aria-label="Data Studio Indicadores"
        ></iframe>
        <chart-solicitudes-seguimiento
          v-if="false"
        ></chart-solicitudes-seguimiento>
      </div>
    </q-card>
  </div>
</template>

<style></style>

<script>
import {
  defineAsyncComponent,
  // ref,
  // onMounted,
} from "vue";

export default {
  name: "indicadores",
  components: {
    ChartSolicitudesSeguimiento: defineAsyncComponent(() =>
      import("@/views/indicadores/components/ChartSolicitudesSeguimiento.vue")
    ),
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
    // SolicitudesSeguimiento: defineAsyncComponent(() =>
    //   import("@/views/vinculacionexterna/components/SolicitudesSeguimiento.vue")
    // ),
    // AnimalApi: defineAsyncComponent(() =>
    //   import("@/components/examples/AnimalApi.vue")
    // ),
  },

  setup() {
    console.log("Indicadores ready");
    // onMounted(() => {});
    return {};
  },
};
</script>

<style></style>
